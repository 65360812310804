import React from 'react';
import { Box, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import styles from './TokenControl.module.scss'

export default function TokenControl(props) {
    return <Grid container className={styles['token-container']}>
        <Box variant="label" className={styles['token-label']}>{props.tokenLabel}</Box>
        <Box className={styles['token-text-container']}>
            {props.loading ?
                <Box className={styles['token-details']}>
                    {
                        props.showToken ? <Box>{props.token}</Box>
                            : <Box>***********</Box>
                    }
                </Box>
                : <Skeleton variant="rectangular" className={styles['token-Skeleton']} />
            }
        </Box>
        <Box className={styles['token-view']} onClick={(e) => props.handleShowHideToken()}>View</Box>
    </Grid>
}

