import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import SubHeader from '../../../components/SubHeader';
import { breadcrumbRSA } from '../../../constants';
import TokenControl from '../TokenControl/index';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loadingSelector } from '../../../redux/Loader';
import { getOneTimeToken, rsaServiceSelector } from '../../../redux/Rsa/Slice';
import styles from './OneTimeToken.module.scss'

export default function OneTimeToken() {
    const navigate = useNavigate();
    const [showToken1, setShowToken1] = React.useState(false);
    const [showToken2, setShowToken2] = React.useState(false);
    const [showToken3, setShowToken3] = React.useState(false);
    const dispatch = useDispatch();
    const { tokenDetails } = useSelector(rsaServiceSelector);
    const { loaderDetails } = useSelector(loadingSelector);

    useEffect(() => {
        if (tokenDetails.length === 0)
            dispatch(getOneTimeToken());
    }, [dispatch]);

    const handleShowHideToken1 = () => {
        setShowToken1(!showToken1);
    }

    const handleShowHideToken2 = () => {
        setShowToken2(!showToken2);
    }
    const handleShowHideToken3 = () => {
        setShowToken3(!showToken3);
    }

    const returnToHome = () => {
        navigate(`/apps`);
    }

    return (
        <>
            <SubHeader breadcrumbs={breadcrumbRSA} />
            <Box className="bg-panel">
                <Box className="card-panel">
                    <Box className="follow-instruction">
                        <h3 className={styles['token-head']}>One-time Token</h3>
                        <TokenControl data-testid="token-control1"
                            loading={!loaderDetails}
                            showToken={showToken1}
                            handleShowHideToken={handleShowHideToken1}
                            token={tokenDetails.token1}
                            tokenLabel="Token 1"                        >
                        </TokenControl>

                        <TokenControl data-testid="token-control2"
                            loading={!loaderDetails}
                            showToken={showToken2}
                            handleShowHideToken={handleShowHideToken2}
                            token={tokenDetails.token2}
                            tokenLabel="Token 2"                         >
                        </TokenControl>

                        <TokenControl data-testid="token-control3"
                            loading={!loaderDetails}
                            showToken={showToken3}
                            handleShowHideToken={handleShowHideToken3}
                            token={tokenDetails.token3}
                            tokenLabel="Token 3"                         >
                        </TokenControl>
                    </Box>
                </Box>

                <Box className="btn-container" >
                    <input data-testid="Yes" type="submit" className="button-primary" value="Done" onClick={() => returnToHome()} />
                </Box>
            </Box>
        </>
    )
}

