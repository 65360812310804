import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import SubHeader from '../../../components/SubHeader';
import { breadcrumbRSA } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loadingSelector } from '../../../redux/Loader';
import { setNewPinCall } from '../../../redux/Rsa/Slice';
import styles from './SetNewPin.module.scss';
import LinearProgress from '@mui/material/LinearProgress';

export default function SetNewPin() {
    const dispatch = useDispatch();
    const { loaderDetails } = useSelector(loadingSelector);

    const { register, watch, handleSubmit, formState: { errors } } = useForm();
    useEffect(() => {

    }, [dispatch])

    const onSubmitNewPin = (data) => {
        dispatch(setNewPinCall(data.newpin, data.confirmnewpin));;
        console.log(data)
    }

    return (
        <>
            {loaderDetails && <LinearProgress color="inherit" />}
            <SubHeader breadcrumbs={breadcrumbRSA} />
            <FormProvider onSubmit={e => e.preventDefault()} >
                <Box className="bg-panel">
                    <Box className="card-panel">
                        <Box className="follow-instruction">
                            <h3 className={styles['token-head']}>Set new PIN</h3>

                            <FormControl sx={{ width: 'calc(min(100%, 38rem))' }}>
                                <InputLabel htmlFor="newpin">Enter New PIN</InputLabel>
                                <OutlinedInput
                                    id="newpin"
                                    type="number"
                                    label="Enter New PIN"
                                    error={!!errors["newpin"]}
                                    aria-describedby="component-error-text"
                                    {...register("newpin", {
                                        required: {
                                            value: true,
                                            message: 'New PIN is required',
                                        },
                                        pattern: {
                                            value: /^[0-9]{8}$/i,
                                            message: "Please enter 8 digit number"
                                        }
                                    })}
                                />
                                <Box sx={{ height: '50px' }}>
                                    {errors.newpin && <FormHelperText error={errors.newpin} id="newpin-error-text">{errors.newpin.message}</FormHelperText>}
                                </Box>
                            </FormControl>

                            <FormControl sx={{ width: 'calc(min(100%, 38rem))' }}>
                                <InputLabel htmlFor="confirmnewpin">Confirm New PIN</InputLabel>
                                <OutlinedInput
                                    id="confirmnewpin"
                                    type="number"
                                    label="Confirm new PIN"
                                    error={!!errors["confirmnewpin"]}
                                    aria-describedby="component-error-text"
                                    {...register("confirmnewpin", {
                                        required: {
                                            value: true,
                                            message: 'Confirm New PIN is required',
                                        },
                                        validate: (val) => {
                                            if (watch('newpin') !== val) {
                                                return "Your new pin do no match";
                                            }
                                            return 'true';
                                        },
                                    })}
                                />
                                <Box sx={{ height: '30px' }}>
                                    {errors.confirmnewpin && <FormHelperText error={errors.confirmnewpin} id="confirmnewpin-error-text">{errors.confirmnewpin.message}</FormHelperText>}
                                </Box>
                            </FormControl>

                        </Box>
                    </Box>

                    <div className="btn-container" >
                        <input data-testid="Cancel" type="submit" className="button-secondary" value="Cancel" />
                        <input data-testid="Submit" type="submit" className="button-primary shift-right" value="Submit" onClick={handleSubmit(onSubmitNewPin)} />
                    </div>
                </Box>
            </FormProvider >

        </>
    )
}

